
import React, {  useContext, useEffect, useState,useRef } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import { SocketContext } from '../../../context/socket'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'
import portalServices from '../portalService'
import PrintToken from '../components/PrintToken'
import UnpaidToken from '../components/UnpaidToken'





const  TokenDetails = ({AppAlert})  => {

    const socket = useContext(SocketContext);

    let navigate = useNavigate();
    const [tokenDetailList,setTokenDetailList] = useState(null)
    

    
    const getCustomerTokenList = ()=>{
        let payload = {customerToken : JSON.parse(localStorage.customerToken)};
        portalServices.getCustomerTokenList(payload).then(res=>{
            setTokenDetailList(null);
            if(res.data.length > 0){
                setTokenDetailList(res.data)
            }
            

        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            
        })
    }   

    

    useEffect(()=>{
        if (localStorage.getItem("customerToken") !== null) {
            getCustomerTokenList();
        }
    },[])


    const [businessDetails,setBusinessDetails] = useState(null)
    useEffect(()=>{
        if (localStorage.getItem("businessDetails") !== null) {
            setBusinessDetails(JSON.parse(localStorage.businessDetails))
        }
    },[])

    return (
        <AppLayout>
            
                <div className="container">
                    {tokenDetailList && tokenDetailList.length > 0 &&
                        <>
                            {tokenDetailList.map((tokenDetails,index) => 
                                
                                    tokenDetails.status === 1 &&

                                        <UnpaidToken key={index} tokenDetails={tokenDetails} businessDetails={businessDetails} AppAlert={AppAlert} socket={socket} getCustomerTokenList={getCustomerTokenList}  />
                                    
                                    
                                
                            )}

                            <div className="card p-2">
                                <Link className="btn btn-success" to="/customer-portal/1">Book Another Token</Link>
                            </div>
                        </>
                    }
                    {!tokenDetailList && 
                        <div className="card p-3 text-center">
                            <h6>No Token Booked</h6>
                            <Link className="btn btn-success" to="/customer-portal/1">Book My Token</Link>
                        </div>
                    }
                </div>
            
        </AppLayout>
    )
}

export default withAlert(TokenDetails);