import React from 'react';
import io from 'socket.io-client'



let appName =  "local"//process.env.REACT_APP_SOCKET_NAME;


switch(process.env.REACT_APP_BASE_URL) {
    case "http://localhost:8000/":
        appName = "local";
        break;
    case "https://hms-api.gtoken.in/":
        appName = "hmsR";
        break;
    case "https://srm-api.gtoken.in/":
            appName = "hms-srm";
            break;
}


//appName = "local";


export const socket = io.connect("https://tms-notification.gtoken.in?app="+appName);
export const SocketContext = React.createContext();